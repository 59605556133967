import React from "react";
import "./Banner.css";
import arrow from "../../images/arrow-bg.svg";
import rust from "../../images/rust.svg";
import react from "../../images/react.svg";
const Banner = () => {
  return (
    <div className="container">
      <div className="scroll">
        <p className="arrow">
          <img src={arrow} alt="arrow" />
        </p>
        <p>SCROLL</p>
        <p></p>
        <p>DOWN</p>
        <p className="arrow">
          <img src={arrow} alt="arrow second" />
        </p>
      </div>
      <div className="content">
        <div className="grid-container">
          <div className="item1">
            {" "}
            <span className="position">I</span>am
          </div>
          <div className="item2">Nick</div>
        </div>
        <div className="description">
          <div className="heroContent">
            <div className="line"></div>
            <div>
              FRONT-END WEB DEVELOPER WITH FULL STACK IDENTITY CRISIS.
              PROFICIENT IN JAVASCRIPT | REACT | NODE | ANGULAR | REACT NATIVE
            </div>
            <div className="line"></div>
          </div>
          <p>
            <span>PART-TIME RUSTACEAN -&gt; THIS WEBSITE IS POWERED BY&nbsp;</span>
            <img src={rust} alt="rust" className="logo" />/
            <img src={react} alt="react" className="logo" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;

import React from "react";
import "./MiddleContent.css";
import socrates from "./../../images/socrates.svg";
const MiddleContent = () => {
  return (
    <div className="middleContent">
      <div className="containerContent">
        <div className="content">
          <h1>What do I do?</h1>
          <p>
            I have 9+ years of experience in building and delivering scalable,
            visually vibrant applications in{" "}
            <span className="redHighLight">JavaScript</span>. I also have
            extensive hands-on experience with frameworks like React, React
            Native and the likes.
          </p>
          <p>
            I have provided solutions via freelancing working with tech-stack
            like Node.js to build MEAN stack applications. I have leveraged my
            full-stack experience to build websites for many clients in areas
            ranging from various sectors like Banking, Airlines, Insurance and
            Consulting.
          </p>
          <p>
            I love working with JavaScript, it is my first language, but besides
            that I have also provided results working in a team with other
            stacks such as Java/Python.
          </p>
          <p>
            Besides that in my free time I love tinkering around in Rust and
            Haskell. More Rust than Haskell these days, I am a big proponent of
            functional paradigm (currying! FTW), and I do love exploring and
            building nerdy stuff. I am unabashed nerdy but thankfully not needy
            🤣
          </p>
          <p>
            In case you want to skip the narrative, Please feel free to checkout
            my &nbsp;
            <a
              href="https://github.com/nickx720"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a> &amp;&nbsp;
            <a
              href="https://gitlab.com/nick_thomas"
              target="_blank"
              rel="noreferrer"
            >
              GitLab
            </a>
          </p>
          <h2>Values I would add in a Team</h2>
          <ul>
            <li>
              Someone who is passionate about delivering passionate products.
            </li>
            <li>Someone who loves to solve to take on challenging problems.</li>
            <li>Not afraid to pick up a new tech stack.</li>
            <li>
              Excellent recommendations in which TV shows/movies to watch and
              books to read!
            </li>
          </ul>
        </div>
      </div>
      <div className="socratesContent">
        <div className="leftContent">
          <div className="quoteBlock">
            <p className="quoteContent">
              "True knowledge exists in knowing that you know nothing."
            </p>
            <p className="referenceQuote">- Socrates</p>
            <img src={socrates} alt="aristotle" className="mobSocrates"></img>
          </div>
          <p>
            Socrates's words hold true for every job in the modern world, but in
            regards to software engineering, it holds the most weight. We are
            part of the most exciting times especially with regards to tech. It
            took me two days to get this site up and running, what would take a
            whole team an entire week a few years ago. I want to be part of a
            project or company who appreciates the fact that software has eaten
            the world.
          </p>
          <p>
            We are part of something bigger than ourselves and on this long
            journey that we call life, we should ensure that our actions stay
            coherent with who we are, and who we want to be. The only constant
            is change, and a person must grow and adapt to survive in this
            hyper-competitive world.
          </p>
        </div>
        <div className="rightContent">
          <img src={socrates} alt="aristotle" className="socrates"></img>
        </div>
      </div>
    </div>
  );
};

export default MiddleContent;

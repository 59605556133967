import {NavLink} from "react-router-dom";
import "./Header.css";
const Header = () => {
  return (
    <header className="header">
      <div className="containers">
        <p>
          <NavLink to ="/">Home </NavLink>
          <NavLink to ="/blog">Blog</NavLink>
        </p>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import "./Footer.css";
import github from "./../../images/github.svg";
import linkedin from "./../../images/linkedin.svg";
/* import rss from "./../../images/rss.svg"; */
import twitter from "./../../images/twitter.svg";
const Footer = () => {
  return (
    <footer>
      <div>
        <a
          href="https://www.linkedin.com/in/nick-thomas-09a96a6a/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="linkedin" className="footerLogo" />
        </a>
        <a href="https://github.com/nickx720" target="_blank" rel="noreferrer">
          <img src={github} alt="github" className="footerLogo" />
        </a>
        <a
          href="https://twitter.com/mvpgreenlantern"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitter} alt="twitter" className="footerLogo" />
        </a>
        {/* <img src={rss} alt="rss" className="footerLogo" /> */}
      </div>
      <div>
        &#169; Nick Tom Thomas{" "}
        <a href="mailto:nicktomthomas@zohomail.eu">Send Email</a>
      </div>
    </footer>
  );
};

export default Footer;

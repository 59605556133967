import "./App.css";
import Header from "./components/Header/Header";
import Banner from "./components/Banner/Banner";
import MiddleContent from "./components/MiddleContent/MiddleContent";
import Footer from "./components/Footer/Footer";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router";
const Home = () => (
  <React.Fragment>
    <Banner />
    <MiddleContent />
    <Footer />
  </React.Fragment>
);
const LazyAdmin = React.lazy(() => import("./pages/Admin/Admin"));
const LazyBlog = React.lazy(() => import("./pages/Blog/Blog"));
const LazyBlogBody = React.lazy(() => import("./pages/Blog/BlogLanding"));

function App() {
  return (
    <div className="App">
      <Header />
      <Suspense fallback={<div>Loading ...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<LazyAdmin />} />
          <Route path="/blog" element={<LazyBlog />} />
          <Route path="/blog/:id" element={<LazyBlogBody />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
